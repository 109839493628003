export const MAYFLOWER_ADDITIONAL_FEE = {
  "11010": {
    province: null,
    regionName: "종로구",
    additionalFee: null,
  },
  "11020": {
    province: null,
    regionName: "중구",
    additionalFee: null,
  },
  "11030": {
    province: null,
    regionName: "용산구",
    additionalFee: null,
  },
  "11040": {
    province: null,
    regionName: "성동구",
    additionalFee: null,
  },
  "11050": {
    province: null,
    regionName: "광진구",
    additionalFee: null,
  },
  "11060": {
    province: null,
    regionName: "동대문구",
    additionalFee: null,
  },
  "11070": {
    province: null,
    regionName: "중랑구",
    additionalFee: null,
  },
  "11080": {
    province: null,
    regionName: "성북구",
    additionalFee: null,
  },
  "11090": {
    province: null,
    regionName: "강북구",
    additionalFee: null,
  },
  "11100": {
    province: null,
    regionName: "도봉구",
    additionalFee: null,
  },
  "11110": {
    province: null,
    regionName: "노원구",
    additionalFee: null,
  },
  "11120": {
    province: null,
    regionName: "은평구",
    additionalFee: null,
  },
  "11130": {
    province: null,
    regionName: "서대문구",
    additionalFee: null,
  },
  "11140": {
    province: null,
    regionName: "마포구",
    additionalFee: null,
  },
  "11150": {
    province: null,
    regionName: "양천구",
    additionalFee: null,
  },
  "11160": {
    province: null,
    regionName: "강서구",
    additionalFee: null,
  },
  "11170": {
    province: null,
    regionName: "구로구",
    additionalFee: null,
  },
  "11180": {
    province: null,
    regionName: "금천구",
    additionalFee: null,
  },
  "11190": {
    province: null,
    regionName: "영등포구",
    additionalFee: null,
  },
  "11200": {
    province: null,
    regionName: "동작구",
    additionalFee: null,
  },
  "11210": {
    province: null,
    regionName: "관악구",
    additionalFee: null,
  },
  "11220": {
    province: null,
    regionName: "서초구",
    additionalFee: null,
  },
  "11230": {
    province: null,
    regionName: "강남구",
    additionalFee: null,
  },
  "11240": {
    province: null,
    regionName: "송파구",
    additionalFee: null,
  },
  "11250": {
    province: null,
    regionName: "강동구",
    additionalFee: null,
  },
  "21010": {
    province: null,
    regionName: "중구",
    additionalFee: null,
  },
  "21020": {
    province: null,
    regionName: "서구",
    additionalFee: null,
  },
  "21030": {
    province: null,
    regionName: "동구",
    additionalFee: null,
  },
  "21040": {
    province: null,
    regionName: "영도구",
    additionalFee: null,
  },
  "21050": {
    province: null,
    regionName: "부산진구",
    additionalFee: null,
  },
  "21060": {
    province: null,
    regionName: "동래구",
    additionalFee: null,
  },
  "21070": {
    province: null,
    regionName: "남구",
    additionalFee: null,
  },
  "21080": {
    province: null,
    regionName: "북구",
    additionalFee: null,
  },
  "21090": {
    province: null,
    regionName: "해운대구",
    additionalFee: null,
  },
  "21100": {
    province: null,
    regionName: "사하구",
    additionalFee: null,
  },
  "21110": {
    province: null,
    regionName: "금정구",
    additionalFee: null,
  },
  "21120": {
    province: null,
    regionName: "강서구",
    additionalFee: null,
  },
  "21130": {
    province: null,
    regionName: "연제구",
    additionalFee: null,
  },
  "21140": {
    province: null,
    regionName: "수영구",
    additionalFee: null,
  },
  "21150": {
    province: null,
    regionName: "사상구",
    additionalFee: null,
  },
  "21310": {
    province: "부산광역시",
    regionName: "기장군",
    additionalFee: 10000,
  },
  "22010": {
    province: null,
    regionName: "중구",
    additionalFee: null,
  },
  "22020": {
    province: null,
    regionName: "동구",
    additionalFee: null,
  },
  "22030": {
    province: null,
    regionName: "서구",
    additionalFee: null,
  },
  "22040": {
    province: null,
    regionName: "남구",
    additionalFee: null,
  },
  "22050": {
    province: null,
    regionName: "북구",
    additionalFee: null,
  },
  "22060": {
    province: null,
    regionName: "수성구",
    additionalFee: null,
  },
  "22070": {
    province: null,
    regionName: "달서구",
    additionalFee: null,
  },
  "22310": {
    province: "대구광역시",
    regionName: "달성군",
    additionalFee: 10000,
  },
  "22520": {
    province: "대구광역시",
    regionName: "군위군",
    additionalFee: 10000,
  },
  "23010": {
    province: null,
    regionName: "중구",
    additionalFee: null,
  },
  "23020": {
    province: null,
    regionName: "동구",
    additionalFee: null,
  },
  "23040": {
    province: null,
    regionName: "연수구",
    additionalFee: null,
  },
  "23050": {
    province: null,
    regionName: "남동구",
    additionalFee: null,
  },
  "23060": {
    province: null,
    regionName: "부평구",
    additionalFee: null,
  },
  "23070": {
    province: null,
    regionName: "계양구",
    additionalFee: null,
  },
  "23080": {
    province: null,
    regionName: "서구",
    additionalFee: null,
  },
  "23090": {
    province: null,
    regionName: "미추홀구",
    additionalFee: null,
  },
  "23310": {
    province: null,
    regionName: "강화군",
    additionalFee: null,
  },
  "23320": {
    province: null,
    regionName: "옹진군",
    additionalFee: null,
  },
  "24010": {
    province: null,
    regionName: "동구",
    additionalFee: null,
  },
  "24020": {
    province: null,
    regionName: "서구",
    additionalFee: null,
  },
  "24030": {
    province: null,
    regionName: "남구",
    additionalFee: null,
  },
  "24040": {
    province: null,
    regionName: "북구",
    additionalFee: null,
  },
  "24050": {
    province: null,
    regionName: "광산구",
    additionalFee: null,
  },
  "25010": {
    province: null,
    regionName: "동구",
    additionalFee: null,
  },
  "25020": {
    province: null,
    regionName: "중구",
    additionalFee: null,
  },
  "25030": {
    province: null,
    regionName: "서구",
    additionalFee: null,
  },
  "25040": {
    province: null,
    regionName: "유성구",
    additionalFee: null,
  },
  "25050": {
    province: null,
    regionName: "대덕구",
    additionalFee: null,
  },
  "26010": {
    province: null,
    regionName: "중구",
    additionalFee: null,
  },
  "26020": {
    province: null,
    regionName: "남구",
    additionalFee: null,
  },
  "26030": {
    province: null,
    regionName: "동구",
    additionalFee: null,
  },
  "26040": {
    province: null,
    regionName: "북구",
    additionalFee: null,
  },
  "26310": {
    province: "울산광역시",
    regionName: "울주군",
    additionalFee: 10000,
  },
  "29010": {
    province: null,
    regionName: "세종특별자치시",
    additionalFee: null,
  },
  "31010": {
    province: null,
    regionName: "수원시",
    additionalFee: null,
  },
  "31020": {
    province: null,
    regionName: "성남시",
    additionalFee: null,
  },
  "31030": {
    province: null,
    regionName: "의정부시",
    additionalFee: null,
  },
  "31040": {
    province: null,
    regionName: "안양시",
    additionalFee: null,
  },
  "31050": {
    province: null,
    regionName: "부천시",
    additionalFee: null,
  },
  "31060": {
    province: null,
    regionName: "광명시",
    additionalFee: null,
  },
  "31070": {
    province: null,
    regionName: "평택시",
    additionalFee: null,
  },
  "31080": {
    province: null,
    regionName: "동두천시",
    additionalFee: null,
  },
  "31090": {
    province: null,
    regionName: "안산시",
    additionalFee: null,
  },
  "31100": {
    province: null,
    regionName: "고양시",
    additionalFee: null,
  },
  "31110": {
    province: null,
    regionName: "과천시",
    additionalFee: null,
  },
  "31120": {
    province: null,
    regionName: "구리시",
    additionalFee: null,
  },
  "31130": {
    province: null,
    regionName: "남양주시",
    additionalFee: null,
  },
  "31140": {
    province: null,
    regionName: "오산시",
    additionalFee: null,
  },
  "31150": {
    province: null,
    regionName: "시흥시",
    additionalFee: null,
  },
  "31160": {
    province: null,
    regionName: "군포시",
    additionalFee: null,
  },
  "31170": {
    province: null,
    regionName: "의왕시",
    additionalFee: null,
  },
  "31180": {
    province: null,
    regionName: "하남시",
    additionalFee: null,
  },
  "31190": {
    province: null,
    regionName: "용인시",
    additionalFee: null,
  },
  "31200": {
    province: null,
    regionName: "파주시",
    additionalFee: null,
  },
  "31210": {
    province: null,
    regionName: "이천시",
    additionalFee: null,
  },
  "31220": {
    province: null,
    regionName: "안성시",
    additionalFee: null,
  },
  "31230": {
    province: null,
    regionName: "김포시",
    additionalFee: null,
  },
  "31240": {
    province: null,
    regionName: "화성시",
    additionalFee: null,
  },
  "31250": {
    province: null,
    regionName: "광주시",
    additionalFee: null,
  },
  "31260": {
    province: null,
    regionName: "양주시",
    additionalFee: null,
  },
  "31270": {
    province: null,
    regionName: "포천시",
    additionalFee: 10000,
  },
  "31280": {
    province: null,
    regionName: "여주시",
    additionalFee: null,
  },
  "31350": {
    province: "경기도",
    regionName: "연천군",
    additionalFee: 10000,
  },
  "31370": {
    province: "경기도",
    regionName: "가평군",
    additionalFee: 10000,
  },
  "31380": {
    province: "경기도",
    regionName: "양평군",
    additionalFee: 10000,
  },
  "32010": {
    province: "경기도",
    regionName: "춘천시",
    additionalFee: 0,
  },
  "32020": {
    province: "경기도",
    regionName: "원주시",
    additionalFee: 10000,
  },
  "32030": {
    province: "경기도",
    regionName: "강릉시",
    additionalFee: 10000,
  },
  "32040": {
    province: "경기도",
    regionName: "동해시",
    additionalFee: 10000,
  },
  "32050": {
    province: "경기도",
    regionName: "태백시",
    additionalFee: 20000,
  },
  "32060": {
    province: "경기도",
    regionName: "속초시",
    additionalFee: 10000,
  },
  "32070": {
    province: "경기도",
    regionName: "삼척시",
    additionalFee: 10000,
  },
  "32310": {
    province: "경기도",
    regionName: "홍천군",
    additionalFee: 10000,
  },
  "32320": {
    province: "경기도",
    regionName: "횡성군",
    additionalFee: 20000,
  },
  "32330": {
    province: "경기도",
    regionName: "영월군",
    additionalFee: 10000,
  },
  "32340": {
    province: "경기도",
    regionName: "평창군",
    additionalFee: 20000,
  },
  "32350": {
    province: "경기도",
    regionName: "정선군",
    additionalFee: 10000,
  },
  "32360": {
    province: "경기도",
    regionName: "철원군",
    additionalFee: 10000,
  },
  "32370": {
    province: "경기도",
    regionName: "화천군",
    additionalFee: 20000,
  },
  "32380": {
    province: "경기도",
    regionName: "양구군",
    additionalFee: 10000,
  },
  "32390": {
    province: "경기도",
    regionName: "인제군",
    additionalFee: 10000,
  },
  "32400": {
    province: "경기도",
    regionName: "고성군",
    additionalFee: 20000,
  },
  "32410": {
    province: "경기도",
    regionName: "양양군",
    additionalFee: 10000,
  },
  "33020": {
    province: null,
    regionName: "충주시",
    additionalFee: null,
  },
  "33030": {
    province: null,
    regionName: "제천시",
    additionalFee: null,
  },
  "33040": {
    province: null,
    regionName: "청주시",
    additionalFee: null,
  },
  "33320": {
    province: "충청북도",
    regionName: "보은군",
    additionalFee: 20000,
  },
  "33330": {
    province: null,
    regionName: "옥천군",
    additionalFee: null,
  },
  "33340": {
    province: null,
    regionName: "영동군",
    additionalFee: 10000,
  },
  "33350": {
    province: null,
    regionName: "진천군",
    additionalFee: 20000,
  },
  "33360": {
    province: null,
    regionName: "괴산군",
    additionalFee: null,
  },
  "33370": {
    province: "충청북도",
    regionName: "음성군",
    additionalFee: 0,
  },
  "33380": {
    province: null,
    regionName: "단양군",
    additionalFee: 10000,
  },
  "33390": {
    province: null,
    regionName: "증평군",
    additionalFee: 20000,
  },
  "34010": {
    province: null,
    regionName: "천안시",
    additionalFee: null,
  },
  "34020": {
    province: null,
    regionName: "공주시",
    additionalFee: null,
  },
  "34030": {
    province: null,
    regionName: "보령시",
    additionalFee: null,
  },
  "34040": {
    province: null,
    regionName: "아산시",
    additionalFee: null,
  },
  "34050": {
    province: null,
    regionName: "서산시",
    additionalFee: null,
  },
  "34060": {
    province: null,
    regionName: "논산시",
    additionalFee: null,
  },
  "34070": {
    province: "충청북도",
    regionName: "계룡시",
    additionalFee: 20000,
  },
  "34080": {
    province: null,
    regionName: "당진시",
    additionalFee: null,
  },
  "34310": {
    province: null,
    regionName: "금산군",
    additionalFee: null,
  },
  "34330": {
    province: null,
    regionName: "부여군",
    additionalFee: null,
  },
  "34340": {
    province: null,
    regionName: "서천군",
    additionalFee: null,
  },
  "34350": {
    province: "충청북도",
    regionName: "청양군",
    additionalFee: 10000,
  },
  "34360": {
    province: null,
    regionName: "홍성군",
    additionalFee: null,
  },
  "34370": {
    province: null,
    regionName: "예산군",
    additionalFee: null,
  },
  "34380": {
    province: "충청북도",
    regionName: "태안군",
    additionalFee: 10000,
  },
  "35010": {
    province: null,
    regionName: "전주시",
    additionalFee: null,
  },
  "35020": {
    province: null,
    regionName: "군산시",
    additionalFee: null,
  },
  "35030": {
    province: null,
    regionName: "익산시",
    additionalFee: null,
  },
  "35040": {
    province: null,
    regionName: "정읍시",
    additionalFee: null,
  },
  "35050": {
    province: "전라북도",
    regionName: "남원시",
    additionalFee: 10000,
  },
  "35060": {
    province: null,
    regionName: "김제시",
    additionalFee: null,
  },
  "35310": {
    province: null,
    regionName: "완주군",
    additionalFee: null,
  },
  "35320": {
    province: "전라북도",
    regionName: "진안군",
    additionalFee: 10000,
  },
  "35330": {
    province: "전라북도",
    regionName: "무주군",
    additionalFee: 10000,
  },
  "35340": {
    province: "전라북도",
    regionName: "장수군",
    additionalFee: 10000,
  },
  "35350": {
    province: "전라북도",
    regionName: "임실군",
    additionalFee: 10000,
  },
  "35360": {
    province: "전라북도",
    regionName: "순창군",
    additionalFee: 10000,
  },
  "35370": {
    province: null,
    regionName: "고창군",
    additionalFee: null,
  },
  "35380": {
    province: null,
    regionName: "부안군",
    additionalFee: null,
  },
  "36010": {
    province: null,
    regionName: "목포시",
    additionalFee: null,
  },
  "36020": {
    province: null,
    regionName: "여수시",
    additionalFee: null,
  },
  "36030": {
    province: null,
    regionName: "순천시",
    additionalFee: null,
  },
  "36040": {
    province: null,
    regionName: "나주시",
    additionalFee: null,
  },
  "36060": {
    province: "전라남도",
    regionName: "광양시",
    additionalFee: 10000,
  },
  "36310": {
    province: null,
    regionName: "담양군",
    additionalFee: null,
  },
  "36320": {
    province: "전라남도",
    regionName: "곡성군",
    additionalFee: 10000,
  },
  "36330": {
    province: "전라남도",
    regionName: "구례군",
    additionalFee: 20000,
  },
  "36350": {
    province: "전라남도",
    regionName: "고흥군",
    additionalFee: 20000,
  },
  "36360": {
    province: null,
    regionName: "보성군",
    additionalFee: null,
  },
  "36370": {
    province: null,
    regionName: "화순군",
    additionalFee: null,
  },
  "36380": {
    province: null,
    regionName: "장흥군",
    additionalFee: 20000,
  },
  "36390": {
    province: null,
    regionName: "강진군",
    additionalFee: null,
  },
  "36400": {
    province: "전라남도",
    regionName: "해남군",
    additionalFee: 10000,
  },
  "36410": {
    province: "전라남도",
    regionName: "영암군",
    additionalFee: 10000,
  },
  "36420": {
    province: null,
    regionName: "무안군",
    additionalFee: null,
  },
  "36430": {
    province: "전라남도",
    regionName: "함평군",
    additionalFee: 10000,
  },
  "36440": {
    province: "전라남도",
    regionName: "영광군",
    additionalFee: 0,
  },
  "36450": {
    province: null,
    regionName: "장성군",
    additionalFee: null,
  },
  "36460": {
    province: null,
    regionName: "완도군",
    additionalFee: null,
  },
  "36470": {
    province: "전라남도",
    regionName: "진도군",
    additionalFee: 10000,
  },
  "36480": {
    province: null,
    regionName: "신안군",
    additionalFee: null,
  },
  "37010": {
    province: null,
    regionName: "포항시",
    additionalFee: null,
  },
  "37020": {
    province: null,
    regionName: "경주시",
    additionalFee: null,
  },
  "37030": {
    province: null,
    regionName: "김천시",
    additionalFee: null,
  },
  "37040": {
    province: null,
    regionName: "안동시",
    additionalFee: null,
  },
  "37050": {
    province: null,
    regionName: "구미시",
    additionalFee: null,
  },
  "37060": {
    province: null,
    regionName: "영주시",
    additionalFee: null,
  },
  "37070": {
    province: null,
    regionName: "영천시",
    additionalFee: null,
  },
  "37080": {
    province: "경상북도",
    regionName: "상주시",
    additionalFee: 10000,
  },
  "37090": {
    province: "경상북도",
    regionName: "문경시",
    additionalFee: 10000,
  },
  "37100": {
    province: null,
    regionName: "경산시",
    additionalFee: null,
  },
  "37320": {
    province: "경상북도",
    regionName: "의성군",
    additionalFee: 10000,
  },
  "37330": {
    province: "경상북도",
    regionName: "청송군",
    additionalFee: 20000,
  },
  "37340": {
    province: "경상북도",
    regionName: "영양군",
    additionalFee: 20000,
  },
  "37350": {
    province: "경상북도",
    regionName: "영덕군",
    additionalFee: 20000,
  },
  "37360": {
    province: null,
    regionName: "청도군",
    additionalFee: null,
  },
  "37370": {
    province: null,
    regionName: "고령군",
    additionalFee: null,
  },
  "37380": {
    province: null,
    regionName: "성주군",
    additionalFee: null,
  },
  "37390": {
    province: null,
    regionName: "칠곡군",
    additionalFee: null,
  },
  "37400": {
    province: null,
    regionName: "예천군",
    additionalFee: 10000,
  },
  "37410": {
    province: null,
    regionName: "봉화군",
    additionalFee: null,
  },
  "37420": {
    province: "경상북도",
    regionName: "울진군",
    additionalFee: 20000,
  },
  "37430": {
    province: "경상북도",
    regionName: "울릉군",
    additionalFee: 20000,
  },
  "38030": {
    province: null,
    regionName: "진주시",
    additionalFee: null,
  },
  "38050": {
    province: null,
    regionName: "통영시",
    additionalFee: null,
  },
  "38060": {
    province: "경상남도",
    regionName: "사천시",
    additionalFee: 0,
  },
  "38070": {
    province: "경상남도",
    regionName: "김해시",
    additionalFee: 0,
  },
  "38080": {
    province: null,
    regionName: "밀양시",
    additionalFee: null,
  },
  "38090": {
    province: null,
    regionName: "거제시",
    additionalFee: null,
  },
  "38100": {
    province: null,
    regionName: "양산시",
    additionalFee: null,
  },
  "38110": {
    province: "경상남도",
    regionName: "창원시",
    additionalFee: 0,
  },
  "38310": {
    province: "경상남도",
    regionName: "의령군",
    additionalFee: 10000,
  },
  "38320": {
    province: "경상남도",
    regionName: "함안군",
    additionalFee: 10000,
  },
  "38330": {
    province: "경상남도",
    regionName: "창녕군",
    additionalFee: 10000,
  },
  "38340": {
    province: "경상남도",
    regionName: "고성군",
    additionalFee: 0,
  },
  "38350": {
    province: null,
    regionName: "남해군",
    additionalFee: null,
  },
  "38360": {
    province: "경상남도",
    regionName: "하동군",
    additionalFee: 10000,
  },
  "38370": {
    province: "경상남도",
    regionName: "산청군",
    additionalFee: 10000,
  },
  "38380": {
    province: null,
    regionName: "함양군",
    additionalFee: null,
  },
  "38390": {
    province: null,
    regionName: "거창군",
    additionalFee: null,
  },
  "38400": {
    province: "경상남도",
    regionName: "합천군",
    additionalFee: 10000,
  },
  "39010": {
    province: "제주특별자치도",
    regionName: "제주시",
    additionalFee: 10000,
  },
  "39020": {
    province: "제주특별자치도",
    regionName: "서귀포시",
    additionalFee: 20000,
  },
} as {
  [key: string]: {
    province: string | null;
    regionName: string;
    additionalFee: number | null;
  };
};
